
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GetData, apiheader } from '../../utils/fetchData';
import Mail from './Mail';
import { Paginator } from 'primereact/paginator';
import { VendersContext } from '../../context/Store';

const Jobs = () => {
  let { isLang } = useContext(VendersContext);

  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [deleteJobsDialog, setDeleteJobsDialog] = useState(false);
  const [IdDeleteJobs, setIdDeleteJobs] = useState(null);
  const [Data, setData] = useState(null);
  const [totalPage, settotalPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State to keep track of the current page
  const [rows, setRows] = useState(1);

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className='btn_gapAr'>
        <Button icon="pi pi-send" size='small' label={isLang === "en" ? 'send mail' : 'أرسل رسالة'} outlined onClick={() => confirmDeleteJobs(rowData)} />
      </div>
    );
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">


      <h4 className="m-0">{isLang === "en" ? "Search by name or email or job_title or country  " : 'البحث حسب الاسم أو البريد الإلكتروني أو المسمى الوظيفي أو البلد'} </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
      </span>
    </div>
  );

  const fetchProfileData = async (page) => {
    const url = `${process.env.REACT_APP_API_URL}/jobs?per_page=${200*1000}`;
    let data = await GetData(url, apiheader);
    console.log(data?.data?.perPage);
    settotalPage(data?.data?.total / data?.data?.perPage);
    setData(data?.data?.data);
    if (data?.status === 200) {
      setTimeout(() => {
        // setIsLaoding(true)
      }, 200);
    }
  }
  useEffect(() => {
    fetchProfileData(currentPage);
  }, [currentPage])


  // Modal Send mail Jobs
  const confirmDeleteJobs = async (Jobs) => {
    setDeleteJobsDialog(true);
    setIdDeleteJobs(Jobs.id)
  };

  const onPageChange = (event) => {
    // setCurrentPage(event.first + 1);
    // setRows(event.rows); 
    const newPage = event.first;
    console.log(newPage);
    setCurrentPage(newPage);
    setRows(event.rows);
  };

  const pdfFileBodyTemplate = (rowData) => {
    return (
      <div className='btn_gapAr'>
        <Button icon="pi pi-file" label={isLang === "en" ? 'Resume  ' : '  السيرة الذاتية'} size='small' rounded text severity="info" onClick={() => window.open(rowData.file, '_blank')} />
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">
        <DataTable
          ref={dt}
          value={Data}
          dataKey="id" 
          globalFilter={globalFilter}
          header={header}
          paginator rows={9}  
        > 

            <Column field="name" header={isLang === "en" ? 'name' : 'الاسم'} style={{ minWidth: '11rem', textAlign: 'center' }} ></Column>
            <Column field="email" header={isLang === "en" ? 'email' : 'بريد إلكتروني'} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
            <Column field="job_title" header={isLang === "en" ? 'مسمى وظيفي' : 'مسمى وظيفي'} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
            <Column field="country" header={isLang === "en" ? 'country' : 'دولة'} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
            <Column body={pdfFileBodyTemplate} header={isLang === "en" ? 'File Cv' : 'ملف السيرة الذاتية'} style={{ minWidth: '11rem', textAlign: 'center' }} />
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }} />

          </DataTable>
       
      </div>

      <Mail isLang={isLang} toast={toast} IdDeleteJobs={IdDeleteJobs} deleteJobsDialog={deleteJobsDialog} setDeleteJobsDialog={setDeleteJobsDialog} />
    </div>
  )
}

export default Jobs
