import LogoSvg from "../../assets/svg/LogoSvg";

const routes = [
  {
    path: "",
    nameEn: "Dashboard",
    nameAr: "لوحة القيادة",
    icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />,
  },


  {
    path: "/modernvision/blogs",
    nameEn: "Modern Vision  Blogs ",
    nameAr: "مقالات رؤية حديثة",
    icon: <LogoSvg.Blogs className="logoSvg" style={{ width: 17 }} />,
  },


  {
    path: "/slider",
    nameEn: "Sliders",
    nameAr: "الصفحه الرئسيه",
    icon: <LogoSvg.Home className="logoSvg" style={{ width: 17 }} />,
  },


  {
    path: "/about",
    nameEn: "About us",
    nameAr: "مــن نــحن ",
    icon: <LogoSvg.Aboutus className="logoSvg" style={{ width: 17 }} />,
  },

  {
    path: "/services",
    nameEn: "Services",
    nameAr: "خدمــاتــنا",
    icon: <LogoSvg.Service className="logoSvg" style={{ width: 17 }} />,
  },

  {
    path: "/blogs",
    nameEn: "Blogs",
    nameAr: "مدونـــاتــنا",
    icon: <LogoSvg.Blogs className="logoSvg" style={{ width: 17 }} />,
  },

  {
    path: "/projects",
    nameEn: "Portfolio",
    nameAr: "أعمــالــنا",
    icon: <LogoSvg.Projects className="logoSvg" style={{ width: 17 }} />,
  },


  {
    path: "/clients",
    nameEn: "Clients",
    nameAr: "عمــلائــنا",
    icon: <LogoSvg.Clients className="logoSvg" style={{ width: 17 }} />,
  }, 
  {
    path: "/contact-us",       
    nameAr: "اتصــال",
    nameEn: "Contact Us",
    icon: <LogoSvg.ContactUs className="logoSvg" style={{ width: 17 }} />,
  },
  {
    path: "/jobs",       
    nameAr: "وظـــائـــف",
    nameEn: "Jops",
    icon: <LogoSvg.Jops className="logoSvg" style={{ width: 17 }} />,
  },
  {
    nameAr: "الاعدادات   ",
    nameEn: "Settings ",
    icon: <LogoSvg.Setting className="logoSvg" style={{ width: 17 }} />,
    Roles: [1],
    subRoutes: [
      {
        path: "/settings/about_us",
        nameAr: "مــن نــحن",
        nameEn: "about_us",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      },
      {
        path: "/settings/our_clients",
        nameAr: " عمــلائــنا  ",
        nameEn: "our_clients",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      },
      {
        path: "/settings/our_projects",
        nameAr: "مشارعنا",
        nameEn: "our_projects",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      },
      {
        path: "/settings/our_services",
        nameAr: "خدماتنا",
        nameEn: "our_services",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      },
      {
        path: "/settings/blogs",
        nameAr: "مدوناتنا",
        nameEn: "blogs",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      },
      {
        path: "/settings/footer",       
         nameAr: "نهاية الصفحه",
        nameEn: "footer",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      },
    ],
  },
  {
    nameAr: "الاعدادات   ",
    nameEn: "Seo ",
    icon: <LogoSvg.Setting className="logoSvg" style={{ width: 17 }} />,
    Roles: [1],
    subRoutes: [
      {
        path: "/seo-slider",
        nameEn: "Sliders",
        nameAr: "الصفحه الرئسيه",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      },
      {
        path: "/seo-about",
        nameEn: "About us",
        nameAr: "مــن نــحن ",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      }, 
      {
        path: "/plan",
        nameEn: "Plans",
        nameAr: "الخطط",
        icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
      }, 
    ],
  },
];

export default routes