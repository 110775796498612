import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img from './../../../assets/Img';
import { PostData, apiheader } from './../../../utils/fetchData';
import { VendersContext } from './../../../context/Store';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

const AddPlan = () => {
    let { isLang } = useContext(VendersContext);
    const url = `${process.env.REACT_APP_API_URL}/seo-stages`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const items = [
        { label: <Link to={'/plan'}>{isLang === "en" ? "stages  " : '    خطه   '}  </Link> },
        { label: <Link to={'/plan/add'} className='bg-orange-100 p-2 border-round'>  {isLang === "en" ? "New Plan  " : '  أضف خطه جديد '} </Link> }
    ];
    const [opendetails, setOpendetails] = useState(false);
    const [openImanes, setOpenImanes] = useState(false);

    const [details, setdetails] = useState([
        { title_en: '', title_ar: '', description_en: '', description_ar: '', image: null }
    ]);

    //!hide images modal 
    const hideImagesProjectsDialog = (e) => setOpenImanes(false)
    //!hide details modal 
    const hidedetailsProjectsDialog = (e) => setOpendetails(false)


    const home = { icon: 'pi pi-home', url: '/' };
    const type = [
        { name: isLang === "en" ? "Branding" : '  العلامة التجارية', value: 0 },
        { name: isLang === "en" ? "Website development" : 'تطوير الموقع  ', value: 1 },
        { name: isLang === "en" ? "Social media marketing" : 'تسويق وسائل الاعلام الاجتماعية', value: 2 },
        // { name: 'Private', value: 'PRIVATE' },
    ];
    const formik = useFormik({
        initialValues: {
            title_en: '',
            title_ar: '',
            type: '',
            details: details,

        },
        onSubmit: async (values, { resetForm }) => {
            // Create a new FormData object for submission
            const formData = new FormData();

            // Append the simple text fields first
            formData.append('title_en', values.title_en);
            formData.append('title_ar', values.title_ar);
            formData.append('type', values.type);

            // Process the details array
            values.details.forEach((detail, index) => {
                // Append each detail field to formData, except 'imagePreview' and 'id'
                Object.entries(detail).forEach(([key, value]) => {
                    if (key !== 'imagePreview' && key !== 'id') {
                        const fieldName = `details[${index}][${key}]`;
                        // Check if it's the image field and if the value is a File object
                        if (key === 'image' && value instanceof File) {
                            formData.append(fieldName, value, value.name);
                        } else if (key !== 'image') {
                            formData.append(fieldName, value);
                        }
                    }
                });
            });
            try {

                setLoading(true);
                let { data } = await PostData(url, formData, apiheader);
                console.log(data);
                if (data?.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/plan')
                        resetForm();
                    }, 1000);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
                    resetForm();
                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });

    // Handle scope change
    const handleScopeChange = (index, language, value) => {
        const updateddetails = [...formik.values.details];
        updateddetails[index][language] = value;
        formik.setFieldValue('details', updateddetails);
    };

    // Handle adding a new scope
    const handleAddScope = () => {
        const updateddetails = [...formik.values.details, { title_en: '', title_ar: '', description_en: '', description_ar: '' }];
        formik.setFieldValue('details', updateddetails);
        setdetails(updateddetails);
    };
    // Function to handle image change
    // Function to handle image change
    const handleImageChange = (index, file) => {
        // Update the details state with the new file and create a preview for the image
        const updatedDetails = details.map((detail, i) => {
            if (index === i) {
                // When the index matches, update the image and imagePreview for this specific detail
                return {
                    ...detail,
                    image: file,
                    imagePreview: file ? URL.createObjectURL(file) : null,
                };
            }
            return detail; // For other indices, return the detail as is
        });

        // Set the updated details with the new image preview into state
        setdetails(updatedDetails);

        // Also update the formik state if necessary
        formik.setFieldValue('details', updatedDetails);
    };

    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="title_en" className="font-bold block mb-2">  {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}    </label>
                        <InputText name='title_en' id="title_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
                        <InputText name='title_ar' id="title_ar" keyfilter="text" className="w-full" dir='rtl' onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                </div>

                <div className="grid  mt-2   ">

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="type" className="font-bold block mb-2">
                            {isLang === "en" ? "Type" : 'نوع'}
                        </label>
                        <Dropdown
                            id="type"
                            name="type"
                            value={formik.values.type}
                            options={type}
                            optionLabel="name"
                            onChange={(e) => {
                                formik.setFieldValue("type", e.value)
                            }}
                            onBlur={formik.handleBlur}
                            placeholder={isLang === "en" ? 'Select a type' : 'اختر النوع'}
                            className="w-full p-inputtext-sm"
                            required
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="link" className="font-bold block mb-2">      {isLang === "en" ? "Add stages" : 'إضافة   مراحل   '} </label>
                        <div className="flex btn_gapAr gap-8">
                            <Button label={isLang === "en" ? "Stage" : 'المراحل  '} type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpendetails(!openImanes)} />
                        </div>
                    </div>
                </div>

                <div className="btn_gapAr  group flex justify-content-center gap-4 ">
                    <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />
                    <Link to={'/plan'}>
                        <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small' className='mt-3' />
                    </Link>
                </div>
            </form>
            {/*****Model Add Multi details */}
            <Dialog visible={opendetails}
                style={{ width: '50%' }}
                header={isLang === "en" ? "details" : 'المراحل  '}
                modal
                dir={isLang === "en" ? 'ltr' : 'rtl'}
                onHide={hidedetailsProjectsDialog}>
                {/* details */}
                {details.map((scope, index) => (
                    <div className="grid" key={index}>
                        <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                            <p className='text-xl text-gray-900 w-full font-bold bg-orange-200 p-2 border-round shadow-2'>{isLang === "en" ? "Stage" : 'المرحله'} {index + 1}</p>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="title_en" className="font-bold block mb-2">  {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}    </label>
                            <InputText
                                name='details'
                                id="details"
                                keyfilter="text"
                                className="w-full"
                                defaultValue={scope.en}
                                onChange={(event) => handleScopeChange(index, 'title_en', event.target.value)}
                            />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
                            <InputText
                                name='details'
                                id="details"
                                keyfilter="text"
                                className="w-full"
                                defaultValue={scope.ar}
                                onChange={(event) => handleScopeChange(index, 'title_ar', event.target.value)}
                            />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "description_en" : '  وصف بالانجليزي'}     </label>
                            <InputText
                                name='details'
                                id="details"
                                keyfilter="text"
                                className="w-full"
                                defaultValue={scope.ar}
                                onChange={(event) => handleScopeChange(index, 'description_en', event.target.value)}
                            />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="description_ar" className="font-bold block mb-2">  {isLang === "en" ? "description_ar" : 'وصف بالعربي'}  </label>
                            <InputText
                                name='details'
                                id="details"
                                keyfilter="text"
                                className="w-full"
                                defaultValue={scope.ar}
                                onChange={(event) => handleScopeChange(index, 'description_ar', event.target.value)}
                            />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor={`image-${index}`} className="font-bold block mb-2">
                                {isLang === "en" ? "Image" : 'صورة'}
                            </label>
                            <input
                                type="file"
                                id={`image-${index}`}
                                name="image"
                                onChange={(event) => handleImageChange(index, event.target.files[0])}
                                className="w-full"
                            />
                            {scope.imagePreview && (
                                <img src={scope.imagePreview} alt={`Preview ${index}`} className="mt-2 w-5rem" />
                            )}
                        </div>
                    </div>


                ))}
                <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
                    <Button label={isLang === "en" ? "New Stage" : 'مرحلة جديد   '} icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
                </div>

            </Dialog>
        </div>
    );
};


export default AddPlan
