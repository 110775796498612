import loginBg from "./Images/loginBg.png";
import logo from "./Images/logo.png";
import DefaultImage from "./Images/default-image.jpg";

const img = {
    loginBg,
    logo,
    DefaultImage
}

export default img;
