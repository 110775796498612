import Vendor from '../Layout/Vendor';
import Navber from './../Components/Navber/Navber';
import Error from './../Components/NotFound/Error';
import Sildebar from './../Components/Sidebar/Sildebar';
import Auth from './../Layout/Auth';
import AboutData from './../Pages/About/AboutData';
import AddAbout from './../Pages/About/AddAbout';
import UpdateAbout from './../Pages/About/UpdateAbout'; 
import Dashboard from './../Pages/Dashbord/Dashbord';
import Profile from './../Pages/Profile/Profile';
import AddSlider from './../Pages/Slider/AddSlider';
import SliderData from './../Pages/Slider/SliderData';
import UpdateSlider from './../Pages/Slider/UpdateSlider';
import Login from './../Pages/auth/Login/Login';
import ServicesData from './../Pages/OurServices/ServicesData';
import AddServices from './../Pages/OurServices/AddServices';
import UpdateServices from './../Pages/OurServices/UpdateServices';
import BlogsData from './../Pages/Blogs/BlogsData';
import AddBlogs from './../Pages/Blogs/AddBlogs';
import UpdateBlogs from './../Pages/Blogs/UpdateBlogs';
import ProjectsData from './../Pages/Projects/ProjectsData';
import AddProjects from './../Pages/Projects/AddProjects';
import UpdateProjects from './../Pages/Projects/UpdateProjects';
import ClientsData from './../Pages/Clients/ClientsData';
import AddClients from './../Pages/Clients/AddClients';
import UpdateClients from './../Pages/Clients/UpdateClients';
import Settings from './../Pages/Settings/Settings';
import ContactUs from './../Pages/ContactUs/ContactUs';
import Jobs from './../Pages/Jobs/Jobs';
import AddBlogsVision from './../ModernVision/AddBlogsVision';
import BlogsVision from './../ModernVision/BlogsVision';
import UpdateBlogsVision from './../ModernVision/UpdateBlogsVision';
import SliderSeo from './../Pages/Seo/SliderSeo/SliderSeo';
import AddSliderSeo from './../Pages/Seo/SliderSeo/AddSliderSeo';
import UpdateSliderSeo from './../Pages/Seo/SliderSeo/UpdateSliderSeo';
import AddAboutSeo from './../Pages/Seo/AboutSeo/AddAboutSeo';
import AboutSeo from './../Pages/Seo/AboutSeo/AboutSeo';
import UpdateAboutSeo from './../Pages/Seo/AboutSeo/UpdateAboutSeo';
import Plan from './../Pages/Seo/Plan/Plan';
import AddPlan from './../Pages/Seo/Plan/AddPlan';
import UpdatePlan from './../Pages/Seo/Plan/UpdatePlan';

const Component = {
    // Layout 
    Vendor,
    Auth,
    Dashboard, 
    Login,
    Plan,
    Navber,
    Sildebar,
    Error,
    Profile,
    // Pages Right click  
    SliderData,
    AddSlider,
    UpdateSlider,
    AboutData,
    UpdateAbout,
    AddAbout,
    ServicesData,
    AddServices,
    UpdateServices,
    BlogsData,
    AddBlogs,
    UpdateBlogs,
    ProjectsData,
    AddProjects,
    UpdateProjects,
    ClientsData,
    AddClients,
    UpdateClients,
    Settings,
    ContactUs,
    Jobs,
    AddBlogsVision,
    BlogsVision,
    UpdateBlogsVision,
    SliderSeo,
    UpdateSliderSeo,
    AddSliderSeo,
    AddAboutSeo,
    AboutSeo,
    AddAboutSeo,
    UpdateAboutSeo,
    AddPlan,
    UpdatePlan
    
}

export default Component


