
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetData, PostData, apiheader } from './../../../utils/fetchData';
import { VendersContext } from './../../../context/Store';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

const UpdatePlan = () => {
    let { isLang } = useContext(VendersContext);
    let { id } = useParams()
    const urlUpdateData = `${process.env.REACT_APP_API_URL}/seo-stages/${id}/update`;
    const urlFetchData = `${process.env.REACT_APP_API_URL}/seo-stages/${id}`;
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const [opendetails, setOpendetails] = useState(false);
    const [openImanes, setOpenImanes] = useState(false);

    const [details, setdetails] = useState([
        { title_en: '', title_ar: '', description_en: '', description_ar: '', image: null }
    ]);

    const items = [
        { label: <Link to={'/plan'}>{isLang === "en" ? "plan  " : '    خطه   '}  </Link> },
        { label: <Link to={`/plan/edit/${id}`} className='bg-orange-100 p-2 border-round'>   {isLang === "en" ? "Update plan  " : '  تعديل خطه  '}</Link> }
    ];
    const fetchSliderData = async () => {
        let { data } = await GetData(urlFetchData, apiheader)
        setData(data);
    }
    useEffect(() => {
        fetchSliderData()
    }, []);
    const home = { icon: 'pi pi-home', url: '/' };
    const type = [
        { name: isLang === "en" ? "Branding" : '  العلامة التجارية', value: 0 },
        { name: isLang === "en" ? "Website development" : 'تطوير الموقع  ', value: 1 },
        { name: isLang === "en" ? "Social media marketing" : 'تسويق وسائل الاعلام الاجتماعية', value: 2 },
        // { name: 'Private', value: 'PRIVATE' },
    ];
    const formik = useFormik({
        initialValues: {
            title_en: Data?.title_en || '',
            title_ar: Data?.title_ar || '',
            type: Data?.type || '',

        },
        onSubmit: async (values, { resetForm }) => {
                 console.log(values?.details);
               /*           let sendData = {
                         ...values, // Spread the original data
                         details: values.details.map(({ id, image, ...rest }) => rest) // Reconstruct details without id and image
                     };
         
                     console.log(sendData); 
                     console.log(sendData); */
            let formData = new FormData();

            // Add the main fields to formData
            formData.append('title_en', values.title_en);
            formData.append('title_ar', values.title_ar);
            formData.append('type', values.type);

            values.details.forEach((detail, index) => {
                Object.keys(detail).forEach(key => {
                    if (key !== 'id' && key !== 'imagePreview') {
                        if (key === 'image') {
                            if (detail[key] instanceof File) {
                                // Append File type image
                                formData.append(`details[${index}][image]`, detail[key], detail[key].name);
                            } else {
                                // Image file not found, append empty string
                                formData.append(`details[${index}][image]`, '');
                            }
                        } else {
                            // Append other details
                            formData.append(`details[${index}][${key}]`, detail[key]);
                        }
                    }
                });
            });
            
            // console.log(formData);
            try {
                setLoading(true);
                let { data } = await PostData(urlUpdateData, formData, apiheader);
                console.log(data);
                if (data?.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/plan')
                        resetForm();
                    }, 1000);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
                    resetForm();
                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);

            }
        }
    });


    //!hide details modal 
    const hidedetailsProjectsDialog = (e) => setOpendetails(false)

    //*****Handle multi-image select 

    // ****Handle scope change
    const handleScopeChange = (index, language, value) => {
        const updateddetails = [...formik.values.details];
        updateddetails[index][language] = value;
        formik.setFieldValue('details', updateddetails);
        console.log(updateddetails);
    };



    useEffect(() => {
        if (Data && Data.details) {
            formik.setValues({
                title_en: Data?.title_en || '',
                title_ar: Data?.title_ar || '',
                type: Data?.type || '',
                details: Data.details
            });
            setdetails(Data.details);
        }
    }, [Data, formik.setValues]);
    // ****Handle Updateing a new scope
    const handleUpdateScope = () => {
        const newScope = { title_en: '', title_ar: '', description_en: '', description_ar: '', image: null };
        const updateddetails = [...formik.values.details, newScope];
        formik.setFieldValue('details', updateddetails);
        setdetails(updateddetails);
    };
    // Function to handle image change

    const handleImageChange = (index, file) => {
        const updatedDetails = [...details];
        updatedDetails[index].image = file;

        if (file) {
            updatedDetails[index].imagePreview = URL.createObjectURL(file);
        }

        setdetails(updatedDetails);
    };
    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="title_en" className="font-bold block mb-2">   {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}      </label>
                        <InputText
                            name='title_en'
                            id="title_en"
                            keyfilter="text"
                            className="w-full p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title_en}
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
                        <InputText value={formik.values.title_ar} name='title_ar' id="title_ar" keyfilter="text" className="w-full p-inputtext-sm" dir='rtl' onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                </div>

                <div className="grid  mt-2   ">

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="type" className="font-bold block mb-2">
                            {isLang === "en" ? "Type" : 'نوع'}
                        </label>
                        <Dropdown
                            id="type"
                            name="type"
                            value={formik.values.type}
                            options={type}
                            optionLabel="name"
                            onChange={(e) => {
                                formik.setFieldValue("type", e.value)
                            }}
                            onBlur={formik.handleBlur}
                            placeholder={isLang === "en" ? 'Select a type' : 'اختر النوع'}
                            className="w-full p-inputtext-sm"
                            required
                        />
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="link" className="font-bold block mb-2">  {isLang === "en" ? "Add stages" : 'إضافة   مراحل   '}   </label>
                        <div className="flex btn_gapAr gap-8">
                            <Button label={isLang === "en" ? "Stage" : 'المراحل  '} type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpendetails(!openImanes)} />
                        </div>
                    </div>
                </div>

                <div className="btn_gapAr group flex justify-content-center gap-4 ">
                    <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />
                    <Link to={'/plan'}>
                        <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small' className='mt-3' />
                    </Link>
                </div>
                {/*****Model Update Multi details */}
                <Dialog visible={opendetails}
                    style={{ width: '50%' }}
                    header={isLang === "en" ? "details" : 'المراحل  '}
                    dir={isLang === "en" ? 'ltr' : 'rtl'}
                    modal
                    onHide={hidedetailsProjectsDialog}>
                    {/* details */}
                    {details.map((scope, index) => (
                        <div className="grid" key={index}>
                            <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                                <p className='text-xl text-gray-900 w-full font-bold bg-orange-200 p-2 border-round shadow-2'>Stage {index + 1}</p>
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="title_en" className="font-bold block mb-2">  {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}    </label>
                                <InputText
                                    name='details'
                                    id="details"
                                    keyfilter="text"
                                    className="w-full"
                                    defaultValue={scope.title_en}
                                    onChange={(event) => handleScopeChange(index, 'title_en', event.target.value)}

                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
                                <InputText
                                    name='details'
                                    id="details"
                                    keyfilter="text"
                                    className="w-full"
                                    defaultValue={scope.title_ar}
                                    onChange={(event) => handleScopeChange(index, 'title_ar', event.target.value)}
                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "description_en" : '  وصف بالانجليزي'}     </label>
                                <InputText
                                    name='details'
                                    id="details"
                                    keyfilter="text"
                                    className="w-full"
                                    defaultValue={scope.description_en}
                                    onChange={(event) => handleScopeChange(index, 'description_en', event.target.value)}
                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="description_ar" className="font-bold block mb-2">  {isLang === "en" ? "description_ar" : 'وصف بالعربي'}  </label>
                                <InputText
                                    name='details'
                                    id="details"
                                    keyfilter="text"
                                    className="w-full"
                                    defaultValue={scope.description_ar}
                                    onChange={(event) => handleScopeChange(index, 'description_ar', event.target.value)}
                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col">
                                <label htmlFor={`image-${index}`} className="font-bold block mb-2">
                                    {isLang === "en" ? "Image" : 'صورة'}
                                </label>
                                <input
                                    type="file"
                                    id={`image-${index}`}
                                    name="image"
                                    onChange={(event) => handleImageChange(index, event.target.files[0])}
                                    className="w-full"
                                />
                                {scope.imagePreview || scope.image ? (
                                    <img
                                        src={scope.imagePreview || scope.image}
                                        alt="Scope Preview"
                                        className="mt-2 w-5rem" // Add your styling as needed
                                    />
                                ) : null}
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-content-center align-items-center btn_gapAr mt-3">
                        <Button label={isLang === "en" ? "New Stage" : 'مرحلة جديد   '}  icon='pi pi-plus-circle' type='button' onClick={handleUpdateScope} />
                    </div>

                </Dialog>
            </form>

        </div>
    );
}

export default UpdatePlan