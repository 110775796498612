import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img from '../../assets/Img';
import { PostData, apiheader } from '../../utils/fetchData';
import { VendersContext } from './../../context/Store';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';

const AddBlogs = () => {

    let { isLang } = useContext(VendersContext);

    const url = `${process.env.REACT_APP_API_URL}/blogs`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const [selectMultiImage, setSelectMultiImage] = useState([]);

    // Handle multi-image select
    const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])

    const items = [
        { label: <Link to={'/blogs'}> {isLang === "en" ? 'Blogs' : 'مدونـــاتــنا'}</Link> },
        { label: <Link to={'/blogs/add'} className='bg-orange-100 p-2 border-round'> {isLang === "en" ? 'Add Blogs' : 'اضافة مقاله'}  </Link> }
    ];

    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageSelect = (event) => {
        setSelectedImage(event.target.files[0]);
    };
    const [openImanes, setOpenImanes] = useState(false);

    //!hide images modal 
    const hideImagesProjectsDialog = (e) => setOpenImanes(false)
    const home = { icon: 'pi pi-home', url: '/' };
    const formik = useFormik({
        initialValues: {
            title_en: '',
            title_ar: '',
            description_en: '',
            description_ar: '',
            short_title_en: '',
            short_title_ar: '',
            short_description_en: '',
            short_description_ar: '',
            image: null,
            images: selectMultiImage,

        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            try {

                setLoading(true);
                let { data } = await PostData(url, values, apiheader);
                console.log(data);
                if (data?.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/blogs')
                        resetForm();
                    }, 1000);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
                    resetForm();
                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
                }

            } catch ({ response }) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                if (response.data.errors?.description_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_en, life: 3000 });
                if (response.data.errors?.description_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_ar, life: 3000 });
                if (response.data.errors?.title_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_en, life: 3000 });
                if (response.data.errors?.title_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_ar, life: 3000 });

                if (response.data.errors?.short_description_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.short_description_en, life: 3000 });
                if (response.data.errors?.short_description_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_ar, life: 3000 });

                if (response.data.errors?.short_title_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.short_title_ar, life: 3000 });
                if (response.data.errors?.short_title_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.short_title_en, life: 3000 });
                setLoading(false);
            }
        }
    });
    useEffect(() => {
        formik.setFieldValue('image', selectedImage);
    }, [selectedImage]);
    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
                <div className="mt-3   flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
                    {formik.values.image ? (
                        <img
                            loading="lazy"
                            src={URL.createObjectURL(formik.values.image)}
                            alt={formik.values.image.name}
                            className=' rounded-circle mx-auto '
                            style={{ border: '6px solid #fff', width: '300px', height: '200' }}
                        />
                    ) :
                        <>
                            <img
                                loading="lazy"
                                src={img.DefaultImage}
                                alt={'default-image'}
                                className=' rounded-circle mx-auto '
                                style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                            />
                        </>
                    }
                    <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                        <input
                            type="file"
                            id="file-input"
                            accept="image/*"
                            onChange={handleImageSelect}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                            <i className='pi pi-image color-wight'></i>
                        </label>
                    </div>
                </div>
                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="title_en" className="font-bold block mb-2">   {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}      </label>
                        <InputText name='title_en' id="title_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
                        <InputText name='title_ar' id="title_ar" keyfilter="text" className="w-full" dir='rtl' onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                </div>

                <div className="grid  mt-2   ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "Description en" : '  وصف بالانجليزي'}     </label>
                        <Editor
                            id="description_en"
                            style={{ height: '320px' }}
                            value={formik.values.description_en}
                            dir='ltr'
                            onTextChange={(e) => formik.setFieldValue("description_en", e.htmlValue)}
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
                        <label htmlFor="description_ar" className="font-bold block mb-2">  {isLang === "en" ? "Description ar" : 'وصف بالعربي'}  </label>
                        <Editor
                            id="description_ar"
                            style={{ height: '320px' }}
                            dir='ltr'
                            value={formik.values.description_ar}
                            onTextChange={(e) => formik.setFieldValue("description_ar", e.htmlValue)}
                        />
                    </div>
                </div>

                <div className="grid  mt-2   ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="short_title_en" className="font-bold block mb-2">     {isLang === "en" ? "short_title_en" : '  عنوان قصير بالانجليزي'}     </label>
                        <InputText name='short_title_en' id="short_title_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
                        <label htmlFor="short_title_ar" className="font-bold block mb-2">   {isLang === "en" ? "short_title_ar" : 'عنوان قصير بالعربي '} </label>
                        <InputText name='short_title_ar' id="short_title_ar" keyfilter="text" className="w-full" dir='rtl'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                </div>

                <div className="grid  mt-2   ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="short_description_en" className="font-bold block mb-2">   {isLang === "en" ? "short_description_en" : '  وصف قصير بالانجليزي'}     </label>
                        <InputText name='short_description_en' id="short_description_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
                        <label htmlFor="short_description_ar" className="font-bold block mb-2">     {isLang === "en" ? "short_description_ar" : '  وصف قصير بالعربي'}  </label>
                        <InputText name='short_description_ar' id="short_description_ar" keyfilter="text" className="w-full" dir='rtl'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="link" className="font-bold block mb-2">      {isLang === "en" ? "Add Images & Scopes" : 'إضافة الصور والنطاقات   '} </label>
                        <div className="flex btn_gapAr gap-8">
                            <Button label={isLang === "en" ? "Images " : ' الصور'} type='button' severity="" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
                        </div>
                    </div>
                </div>

                <div className="btn_gapAr group flex justify-content-center gap-4 ">
                    <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small'  className='mt-3' />
                    <Link to={'/blogs'}>
                        <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small'  className='mt-3' />
                    </Link>
                </div>
            </form>

            <Dialog visible={openImanes}
                style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                modal
                onHide={hideImagesProjectsDialog}
                header={isLang === "en" ? "Multi Images" : "صور متعددة"}
                dir={isLang === "en" ? 'ltr' : 'rtl'}

            >
                {/*      Add DataTable to display data} */}
                <div className="mt-3 mb-3 flex justify-content-center">

                    <label htmlFor='fileInput ' className='btn_gapAr' >
                        <Button label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                    </label>
                </div>
                <input
                    type='file'
                    id='fileInput'
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue('images', [...selectMultiImage, event.target.files[0]]);
                        handleMultiImageSelect(event);
                    }}
                />
                {/*  Display selected images */}

                <div className="grid  mt-2   ">
                    {selectMultiImage.map((image, index) => (
                        <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <img src={URL.createObjectURL(image)} alt={`Image ${index}`} className='w-full h-8rem border-round-xl' />
                        </div>
                    ))}
                </div>


                <button type='button' style={{ display: 'none' }} onClick={() => document.getElementById('fileInput').click()}>
                    Choose Images
                </button>
            </Dialog>
        </div>
    )
}

export default AddBlogs