import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apiheader } from '../../utils/fetchData';
import { fetchSliderData, useUpdateFormik, handleDeleteImage } from './../../helper/useProjects';
import axios from 'axios';

import { VendersContext } from './../../context/Store';
import { Dropdown } from 'primereact/dropdown';
const UpdateProjects = () => {
  let { isLang } = useContext(VendersContext);
  // TODO End point
  let { id } = useParams();
  const url = `${process.env.REACT_APP_API_URL}/projects/${id}/update`;
  const urlFetchData = `${process.env.REACT_APP_API_URL}/projects/${id}`;
  const options = [
    { label: isLang === "en" ? 'Web Development' : "تطوير المواقع", value: 'web_development' },
    { label: isLang === "en" ? 'Account Management' : "إدارة الحسابات", value: 'account_management' },
    { label: isLang === "en" ? 'Digital Marketing' : "التسويق الرقمي", value: 'digital_marketing' },
    { label: isLang === "en" ? 'Event Organization' : "التواصل الداخلي و تنظيم الفعاليات", value: 'event_organization' },
    { label: isLang === "en" ? 'Visual Production' : "الإنتاج المرئي", value: 'visual_production' },
    { label: isLang === "en" ? 'Visual Identity' : "الهوية البصرية", value: 'visual_identity' },
  ];
  // Navigation
  let navigate = useNavigate();

  // Refs
  const toast = useRef(null);

  //  State variables
  const [loading, setLoading] = useState(false);
  const [openImanes, setOpenImanes] = useState(false);
  const [openScopes, setOpenScopes] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const [scopes, setScopes] = useState([{ en: '', ar: '' }]);
  const [editedImages, setEditedImages] = useState([]);
  const [Data, setData] = useState(false);

  const fetchSliderDataAndUpdateState = async () => {
    await fetchSliderData(urlFetchData, apiheader, setData, setScopes, setEditedImages);
  };

  useEffect(() => {
    fetchSliderDataAndUpdateState();
  }, [id]);

  useEffect(() => {
    fetchSliderData()
  }, [id])

  // TODO Handle 1 image select
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
  // TODO Handle 1 logo select
  const handleLogoSelect = (event) => setSelectedLogo(event.target.files[0]);

  //!hide images modal 
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //!hide Scopes modal 
  const hideScopesProjectsDialog = (e) => setOpenScopes(false)

  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])

  // ****Handle scope change
  const handleScopeChange = (index, language, value) => {
    const updatedScopes = [...formik.values.scopes];
    updatedScopes[index][language] = value;
    formik.setFieldValue('scopes', updatedScopes);
  };

  // ****Handle Updateing a new scope
  const handleUpdateScope = () => {
    const updatedScopes = [...formik.values.scopes, { ar: '', en: '' }];
    formik.setFieldValue('scopes', updatedScopes);
    setScopes(updatedScopes);
  };

  // Set image and logo in formik values when selected
  useEffect(() => {
    formik.setFieldValue('image', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    formik.setFieldValue('logo', selectedLogo);
  }, [selectedLogo]);

  // Breadcrumb items
  const items = [
    {
      label: <Link to={'/projects'}> {isLang === "en" ? '  Projects' : '  المشاريع '}
      </Link>
    },
    {
      label: (<Link to={`/projects/edit/${id}`} className='bg-orange-100 p-2 border-round'> {isLang === "en" ? 'Update Project' : 'تعديل بيانات مشروع '}
      </Link>),
    },
  ];

  // Formik setup
  const formik = useUpdateFormik({
    scopes,
    selectMultiImage,
    editedImages,
    url,
    apiheader,
    toast,
    navigate,
    setLoading,
  });


  useEffect(() => {
    formik.setValues({
      title_en: Data?.title_en || '',
      title_ar: Data?.title_ar || '',
      description_en: Data?.description_en || '',
      description_ar: Data?.description_ar || '',
      duration: Data?.duration || '',
      section: Data?.section || '',
      link: Data?.link || '',
      images: Data?.images || selectMultiImage,
      scopes: Data?.scopes || scopes,
    });
  }, [Data, formik.setValues]);

  //*****Handle multi-image delete
  // const handleDeleteImageFunc = async (id) => {
  //   const success = await handleDeleteImage(id, apiheader, toast);
  //   console.log(success);
  //   if (success) {
  //     fetchSliderDataAndUpdateState();
  //   }
  // };
  // delte images
  const handleDeleteImageFunc = async (id) => {

    await axios.delete(`${process.env.REACT_APP_API_URL}/projects/${id}/delete-image`, apiheader).then(({ data }) => {
      console.log(data);
      if (data?.status) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });
        fetchSliderDataAndUpdateState()
        setTimeout(() => {
        }, 1000);
      }
    }).catch(({ response }) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
      if (response.data.errors?.description_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_en, life: 3000 });
      if (response.data.errors?.description_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_ar, life: 3000 });
      if (response.data.errors?.title_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_en, life: 3000 });
      if (response.data.errors?.title_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_ar, life: 3000 });
      if (response.data.errors?.link) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.link, life: 3000 });
      if (response.data.errors?.duration) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.duration, life: 3000 });
      if (response.data.errors?.image) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.image, life: 3000 });
      if (response.data.errors?.images) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.images, life: 3000 });
      if (response.data.errors?.scopes) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.scopes, life: 3000 });
      if (response.data.errors?.logo) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.logo, life: 3000 });
      if (response.data.errors?.section) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.section, life: 3000 });
    })

  };
  return (
    <div>
      <Toast ref={toast} position={isLang==="en"?'top-right':'top-left'} />
      <BreadCrumb model={items} home={{ icon: 'pi pi-home', url: '/' }} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11 m-auto'>
        <div className="mt-3 grid   flex justify-content-between  m-auto "  >
          <div style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
            {!formik.values.image ? (
              <img
                loading="lazy"
                src={Data.image}
                alt={'image main project'}
                className='image_view rounded-circle mx-auto '
                style={{ border: '6px solid #fff', width: '300px', height: '200' }}
              />
            ) :
              <>
                <img
                  loading="lazy"
                  src={URL.createObjectURL(formik.values.image)}
                  alt={'default-image'}
                  className='image_view rounded-circle mx-auto '
                  style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                />
              </>
            }
            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
              <input
                type="file"
                id="iamge-upload"
                accept="image/*"
                onChange={handleImageSelect}
                style={{ display: 'none' }}
              />
              <label htmlFor="iamge-upload" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                <i className='pi pi-image color-wight'></i>
              </label>
            </div>
          </div>

          <div style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>

            {formik.values.logo ? (
              <img
                loading="lazy"
                src={URL.createObjectURL(formik.values.logo)}
                alt={Data.image}
                className='image_view rounded-circle mx-auto '
                style={{ border: '6px solid #fff', width: '300px', height: '200px' }}
              />
            ) :
              <>
                <img
                  loading="lazy"
                  src={Data.logo}
                  alt={'default-image'}
                  className='image_view rounded-circle mx-auto '
                  style={{ border: '6px solid #fff', width: '300px', maxHeight: '172px' }}
                />
              </>
            }
            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
              <input
                type="file"
                id="logo-upload"
                accept="image"
                onChange={handleLogoSelect}
                style={{ display: 'none' }}
              />
              <label htmlFor="logo-upload" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                <i className='pi pi-image color-wight'></i>
              </label>
            </div>
          </div>

        </div>


        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="title_en" className="font-bold block mb-2">   {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}    </label>
            <InputText defaultValue={Data?.title_en} name='title_en' id="title_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
            <InputText defaultValue={Data?.title_ar} name='title_ar' id="title_ar" keyfilter="text" className="w-full" dir='rtl' onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "description_en" : '  وصف بالانجليزي'}     </label>
            <InputText defaultValue={Data?.description_en} name='description_en' id="description_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
            <label htmlFor="description_ar" className="font-bold block mb-2">   {isLang === "en" ? "description_ar" : 'وصف بالعربي'} </label>
            <InputText defaultValue={Data?.description_ar} name='description_ar' id="description_ar" keyfilter="text" className="w-full" dir='rtl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="duration" className="font-bold block mb-2">   {isLang === "en" ? "duration" : 'المدة الزمنية'}     </label>
            <InputText defaultValue={Data?.duration} name='duration' id="duration" keyfilter="int" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
            <label htmlFor="section" className="font-bold block mb-2">    {isLang === "en" ? "section" : 'قسم   '}  </label>
            <div className={`${isLang === "en" ? 'p-dropdown-itemEn' : 'p-dropdown-itemAr'}`}>

              <Dropdown
                id="section"
                name="section"
                value={formik.values.section}
                options={options}
                onChange={(e) => {
                  formik.setFieldValue('section', e.value); // تحديث قيمة في Formik
                }}
                onBlur={formik.handleBlur}
                className="w-full"
                placeholder={isLang === "en" ? "Choose a section" : "اختر قسمًا"}
              />

            </div>
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">  {isLang === "en" ? "Youtube Link" : 'رابط يوتيوب '}   </label>
            <InputText defaultValue={Data?.link} name='link' id="link" keyfilter="text" className="w-full" onChange={formik.handleChange} onBlur={formik.handleBlur} />
          </div>


          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">  {isLang === "en" ? "Add Images & Scopes" : 'إضافة الصور والنطاقات   '}   </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={isLang === "en" ? "Images " : ' الصور'}  type='button' severity="" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
              <Button label={isLang === "en" ? "Scopes" : 'النطاقات   '} type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenScopes(!openImanes)} />
            </div>
          </div>
        </div> 
        
        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Multi Images" : "صور متعددة"}
          dir={isLang === "en" ? 'ltr' : 'rtl'} 
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
              <label htmlFor='fileInput '  className='btn_gapAr' >
              <Button label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
            </label>
                <input
                  type='file'
                  id='fileInput'
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('images', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                  <img src={URL.createObjectURL(image)} alt={`Image ${index}`} className='w-full h-8rem border-round-xl' />
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {editedImages.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col">
                  <div className="relative  ">
                    <img
                      src={item.image}
                      alt={`Image ${index}`}
                      style={{objectFit:'cover'}}
                      className="w-full h-8rem border-round-xl"
                    />
                    <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute right-0 " onClick={() => handleDeleteImageFunc(item.id)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Dialog>



        {/*****Model Update Multi Scopes */}
        <Dialog visible={openScopes}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Scopes" : 'النطاقات   '}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideScopesProjectsDialog}>
          {/* Scopes */}
          {scopes.map((scope, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="scopes" className="font-bold block mb-2">    {isLang === "en" ? "  Scope_En" : 'نِطَاق إنجليزي   '}   </label>
                <InputText
                  name='scopes'
                  id="scopes"
                  keyfilter="text"
                  className="w-full"
                  defaultValue={scope.en}
                  onChange={(event) => handleScopeChange(index, 'en', event.target.value)}
                />
              </div>
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="scopes" className="font-bold block mb-2">   {isLang === "en" ? "  Scope_Ar" : 'نِطَاق عربي   '}   </label>
                <InputText
                  name='scopes'
                  id="scopes"
                  keyfilter="text"
                  className="w-full"
                  defaultValue={scope.ar}
                  onChange={(event) => handleScopeChange(index, 'ar', event.target.value)}
                />
              </div>
            </div>
          ))}
          <div className="flex justify-content-center align-items-center btn_gapAr mt-3">
            <Button label={isLang === "en" ? "New Scope" : 'نِطَاق جديد   '} icon='pi pi-plus-circle' type='button' onClick={handleUpdateScope} />
          </div>

        </Dialog>
        <div className="group btn_gapAr flex btn_gapAr justify-content-center gap-4 ">
          <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small'  className='mt-3' />
          <Link to={'/projects'}>
            <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small'  className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default UpdateProjects
