import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetData, apiheader } from '../../utils/fetchData';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalProjectData from './ModalProjectData';
import { VendersContext } from '../../context/Store';


const ProjectsData = () => {

  let { isLang } = useContext(VendersContext);

  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [deleteprojectsDialog, setDeleteprojectsDialog] = useState(false);
  const [IdDeleteprojects, setIdDeleteprojects] = useState(null);
  const [Data, setData] = useState([]);
  const [deleteLoadind, setdeleteLoadind] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Initialize current page number
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages to 1

  // Add new projects
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2">
        <Link to="/projects/add">
          <Button size='small' label={isLang === "en" ? "New project  " : 'إضافة مشروع جديد'} icon="pi pi-plus" severity="success" />
        </Link>
      </div>
    );
  };

  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.image} alt={rowData.image} className="shadow-2 border-round" style={{ width: '150px' }} />;
  };

  // handel image in tabel
  const logoBodyTemplate = (rowData) => {
    return <img src={rowData.logo} alt={rowData.image} className="shadow-2 border-round  " style={{ width: '100px', background: '#000' }} />;
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2  ">
        <Link to={`/projects/edit/${rowData.id}`}>
          <Button icon="pi pi-pencil" rounded outlined className="mr-2" />
        </Link>
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteprojects(rowData)} />
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{isLang === "en" ? "Search by title or description " : '   البحث حسب العنوان أو الوصف '} </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
      </span>
    </div>
  );


  const getAllContactUsClients = async (page) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/projects`;
      const data = await GetData(url, apiheader);
      setTotalPages(data?.data?.lastPage || 1);
      return data?.data?.data;
    } catch (error) {
      console.error('Error while fetching contact data:', error);
      return [];
    }
  };

  const fetchMoreData = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1; // Calculate the next page
      getAllContactUsClients(nextPage).then((newData) => {
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(nextPage); // Update the currentPage state
      });
    }
  };

  useEffect(() => {
    getAllContactUsClients(currentPage).then((data) => {
      setData(data);
    });
  }, []);

  const confirmDeleteprojects = async (projects) => {
    setDeleteprojectsDialog(true);
    setIdDeleteprojects(projects.id)
  };
  const deleteprojects = async () => {
    setdeleteLoadind(true)
    setData([]);
    await axios.delete(`${process.env.REACT_APP_API_URL}/projects/${IdDeleteprojects}`, apiheader).then(({ data }) => {
      console.log(data);
      if (data?.status) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });
        setCurrentPage(1)
        getAllContactUsClients(currentPage).then((data) => {
          setData(data);
        });
        setTimeout(() => {
          setDeleteprojectsDialog(false);
          getAllContactUsClients(currentPage)
          setdeleteLoadind(false)
        }, 1000);
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setdeleteLoadind(false)
    })

  };
  const hideDeleteprojectsDialog = () => setDeleteprojectsDialog(false);
  const deleteprojectsDialogFooter = (
    <div className='btn_gapAr'>
      <Button label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteprojectsDialog} />
      <Button label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} icon="pi pi-check" severity="danger" onClick={deleteprojects} />
    </div>
  );

  // slice description in tabel view
  const getDescriptionPreviewEn = (rowData) => rowData.description_en.split(' ').slice(0, 15).join(' ');
  const getDescriptionPreviewAr = (rowData) => rowData.description_ar.split(' ').slice(0, 15).join(' ');


  const [scopes, setScopes] = useState([]);
  const [openscopes, setOpenScopes] = useState(false);

  const scopesTemplate = (rowData) => {
    setScopes([])
    setScopes(rowData)
    setOpenScopes(true)
  };
  const fetchScopeTemplate = (rowData) => {
    return (
      <div className='btn_gapAr'>
        <Button icon="pi pi-info-circle  " label={isLang === "en" ? "Scope " : 'نِطَاق  '} size='small' severity="Success" onClick={() => scopesTemplate(rowData.scopes)} />
      </div>
    );
  };
  // Images array
  const [Iamges, setIamges] = useState([]);
  const [openIamges, setOpenIamges] = useState(false);

  const IamgesTemplate = (rowData) => {
    setIamges([])
    setIamges(rowData)
    setOpenIamges(true)
  };
  const fetchImagesTemplate = (rowData) => {
    return (
      <div className='btn_gapAr'>
        <Button icon="pi pi-images"  label={isLang === "en" ? "Images " : 'الصور  '} size='small' severity="success" onClick={() => IamgesTemplate(rowData.images)} />
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} position={isLang==="en"?'top-right':'top-left'} />
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        <InfiniteScroll
          dataLength={Data.length}
          next={fetchMoreData}
          hasMore={currentPage < totalPages}
          loader={<h4>Loading...</h4>}
        >
          <DataTable
            ref={dt}
            value={Data}
            dataKey="id"
            globalFilter={globalFilter}
            header={header}>
            <Column field="image" header={isLang === "en" ? "images" : 'الصور'} body={imageBodyTemplate}></Column>
            <Column field="image" header={isLang === "en" ? "Logo" : 'لوجو'} body={logoBodyTemplate}></Column>
            <Column field="duration" header={isLang === "en" ? "duration" : 'المدة الزمنية'} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
            <Column field="title_en" header={isLang === "en" ? "title_en" : 'العنوان بالانجليزي'} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
            <Column field="title_ar" header={isLang === "en" ? "title_ar" : 'العنوان بالعربي '} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
            <Column field="section" header={isLang === "en" ? "section" : 'قسم   '} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
            <Column field="description_en" header={isLang === "en" ? "description_en" : '  وصف بالانجليزي'} body={(rowData) => getDescriptionPreviewEn(rowData)} style={{ minWidth: '15rem', textAlign: 'center' }}></Column>
            <Column field="description_ar" header={isLang === "en" ? "description_ar" : 'وصف بالعربي'} body={(rowData) => getDescriptionPreviewAr(rowData)} style={{ minWidth: '15rem', textAlign: 'center' }}></Column>
            <Column header={isLang === "en" ? "Scopes" : 'النطاقات'} body={fetchScopeTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
            <Column header={isLang === "en" ? "images" : 'الصور'} body={fetchImagesTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
          </DataTable>
        </InfiniteScroll>
      </div>

      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteprojectsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteprojectsDialogFooter} onHide={hideDeleteprojectsDialog} >
        <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>
            {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
          </span>
        </div>
      </Dialog>



      <ModalProjectData
        isLang={isLang}
        data={scopes} setOpen={setOpenScopes} open={openscopes}
        dataImages={Iamges} setOpenImages={setOpenIamges} openImages={openIamges}
      />
    </div>
 ) 
}

export default ProjectsData