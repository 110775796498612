import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img from './../../../assets/Img';
import { PostData, apiheader } from './../../../utils/fetchData';
import { VendersContext } from './../../../context/Store';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';

const AddAboutSeo = () => {
  let { isLang } = useContext(VendersContext);

  const url = `${process.env.REACT_APP_API_URL}/about-seo`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/seo-about'}>  {isLang === "en" ? "Abouts  " : '    من نحن   '} </Link> },
    { label: <Link to={'/seo-about/add'} className='bg-orange-100 p-2 border-round'>{isLang === "en" ? "Add Abouts  " : 'إضافه نبذة  عنا'}   </Link> }
  ];
  const type = [
    { name: isLang === "en" ? "Branding" : '  العلامة التجارية', value: 0 },
    { name: isLang === "en" ? "Website development" : 'تطوير الموقع  ', value: 1 },
    { name: isLang === "en" ? "Social media marketing" : 'تسويق وسائل الاعلام الاجتماعية', value: 2 },
    // { name: 'Private', value: 'PRIVATE' },
  ];

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      type: '',

    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {

        setLoading(true);
        let { data } = await PostData(url, values, apiheader);
        console.log(data);
        if (data?.status === 200) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/seo-about')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);

      }
    }
  });

  return (
    <div>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="title_en" className="font-bold block mb-2">   {isLang === "en" ? "Title in english" : 'العنوان بالانجليزي'}      </label>
            <InputText name='title_en' id="title_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "Title in arabic" : 'العنوان بالعربي '} </label>
            <InputText name='title_ar' id="title_ar" keyfilter="text" className="w-full" dir='rtl' onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "  Description in english" : '  وصف بالانجليزي'}     </label>
            <InputTextarea name='description_en' id="description_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
            <label htmlFor="description_ar" className="font-bold block mb-2">  {isLang === "en" ? "Description in arabic" : 'وصف بالعربي'}  </label>
            <InputTextarea name='description_ar' id="description_ar" keyfilter="text" className="w-full" dir='rtl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="grid  mt-2   ">
            {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "Description en" : '  وصف بالانجليزي'}     </label>
              <Editor
                id="description_en"
                style={{ height: '320px' }}
                value={formik.values.description_en}
                dir='ltr'
                onTextChange={(e) => formik.setFieldValue("description_en", e.htmlValue)}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
              <label htmlFor="description_ar" className="font-bold block mb-2">  {isLang === "en" ? "Description ar" : 'وصف بالعربي'}  </label>
              <Editor
                id="description_ar"
                style={{ height: '320px' }}
                dir='ltr'
                value={formik.values.description_ar}
                onTextChange={(e) => formik.setFieldValue("description_ar", e.htmlValue)}
              />
            </div> */}
          </div>
          <div  className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
            <label htmlFor="type" className="font-bold block mb-2">
              {isLang === "en" ? "Type" : 'نوع'}
            </label>
            <Dropdown
              id="type"
              name="type"
              value={formik.values.type}
              options={type}
              optionLabel="name"
              onChange={(e) => {
                formik.setFieldValue("type", e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a type' : 'اختر النوع'}
              className="w-full p-inputtext-sm"
              required 
            />
          </div>
        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 ">
          <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small' className='mt-3' />
          <Link to={'/seo-about'}>
            <Button size='small' label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default AddAboutSeo
