import axios from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { VendersContext } from '../../context/Store';
import { GetData, apiheader } from '../../utils/fetchData';

const ServicesData = () => {
  let { isLang } = useContext(VendersContext);

  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [deleteServicesDialog, setDeleteServicesDialog] = useState(false);
  const [IdDeleteServices, setIdDeleteServices] = useState(null);
  const [Data, setData] = useState(null);
  const [deleteLoadind, setdeleteLoadind] = useState(false);



  // Add new Services
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2">
        <Link to="/services/add">
          <Button size='small' label={isLang === "en" ? "Add Services" : 'إضافه خدمه '} icon="pi pi-plus" severity="success" />
        </Link>
      </div>
    );
  };

  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.image} alt={rowData.image} className="shadow-2 border-round" style={{ width: '150px' }} />;
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2">
        <Link to={`/services/edit/${rowData.id}`}>
          <Button icon="pi pi-pencil" rounded outlined className="mr-2" />
        </Link>
        {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteServices(rowData)} /> */}
      </div>
    );
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{isLang === "en" ? "Search by title or description " : '   البحث حسب العنوان أو الوصف '} </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
    </div>
  );

  const fetchProfileData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/services`;
    let data = await GetData(url, apiheader);
    setData(data?.data?.data);
    if (data?.status === 200) {
      setTimeout(() => {
        // setIsLaoding(true)
      }, 200);
    }
  }
  useEffect(() => {
    fetchProfileData();
  }, [])


  // delete Services
  const confirmDeleteServices = async (Services) => {
    setDeleteServicesDialog(true);
    setIdDeleteServices(Services.id)
  };
  const deleteServices = async () => {
    setdeleteLoadind(true)
    await axios.delete(`${process.env.REACT_APP_API_URL}/services/${IdDeleteServices}`, apiheader).then(({ data }) => {
      console.log(data);
      if (data?.status) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });

        setTimeout(() => {
          setDeleteServicesDialog(false);
          fetchProfileData()
          setdeleteLoadind(false)
        }, 1000);
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setdeleteLoadind(false)
    })

  };
  const hideDeleteServicesDialog = () => setDeleteServicesDialog(false);
  const deleteServicesDialogFooter = (
    <div className='btn_gapAr'>
      <Button label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteServicesDialog} />
      <Button label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} icon="pi pi-check" severity="danger" onClick={deleteServices} />
    </div>
  );

  // slice description in tabel view
  const getDescriptionPreviewEn = (rowData) => rowData.description_en.split(' ').slice(0, 15).join(' ');
  const getDescriptionPreviewAr = (rowData) => rowData.description_ar.split(' ').slice(0, 15).join(' ');
  return (
    <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
      <Toast ref={toast} position={isLang==="en"?'top-right':'top-left'} />
      <div className="card">
        {/* <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar> */}

        <DataTable
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="image" header={isLang === "en" ? "images" : 'الصور'} body={imageBodyTemplate}></Column>
          <Column field="title_en" header={isLang === "en" ? "title_en" : 'العنوان بالانجليزي'} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
          <Column field="title_ar" header={isLang === "en" ? "title_ar" : 'العنوان بالعربي '} style={{ minWidth: '11rem', textAlign: 'center' }}></Column>
          <Column field="description_en" header={isLang === "en" ? "description_en" : '  وصف بالانجليزي'} body={(rowData) => getDescriptionPreviewEn(rowData)} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="description_ar" header={isLang === "en" ? "description_ar" : 'وصف بالعربي'} body={(rowData) => getDescriptionPreviewAr(rowData)} style={{ minWidth: '15rem', textAlign: 'center' }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
      </div>

      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteServicesDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteServicesDialogFooter} onHide={hideDeleteServicesDialog}  >
        <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>
            {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
          </span>
        </div>
      </Dialog>
    </div>
  )
}

export default ServicesData