import { useFormik } from 'formik';
import { PostData } from '../utils/fetchData';
import { GetData } from '../utils/fetchData';
import axios from 'axios';

// update data
export const useUpdateFormik = ({ scopes, selectMultiImage, editedImages, url, apiheader, toast, navigate, setLoading }) => {
    return useFormik({
        initialValues: {
            title_en: '',
            title_ar: '',
            description_en: '',
            description_ar: '',
            image: null,
            logo: null,
            duration: '',
            section: '',
            link: '',
            scopes: scopes,
            images: selectMultiImage,
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const dataToSend = {
                    ...values,
                    images: selectMultiImage,
                };
                setLoading(true);
                let { data } = await PostData(url, dataToSend, apiheader);
                console.log(data);
                if (data?.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/projects')
                        resetForm();
                    }, 1000);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
                    resetForm();
                    setLoading(false);
                } else {
                    console.log(data);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
                }

            } catch ({ response }) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                if (response.data.errors?.image) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.image[0], life: 3000 });
                if (response.data.errors?.link) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.link[0], life: 3000 });
                setLoading(false);

            }
        },
    });
};


// show data
export const fetchSliderData = async (urlFetchData, apiheader, setData, setScopes, setEditedImages) => {
    try {
        let { data } = await GetData(urlFetchData, apiheader);
        setData(data);
        setScopes(data.scopes);
        setEditedImages(data.images);
    } catch (error) {
        console.error(error);
    }
};

// delte images
export const handleDeleteImage = async (id, apiheader, toast) => {

    await axios.delete(`${process.env.REACT_APP_API_URL}/projects/${id}/delete-image`, apiheader).then(({ data }) => {
        console.log(data);
        if (data?.status) {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });
            fetchSliderData()
            setTimeout(() => {
            }, 1000);
        }
    }).catch((error) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
        // setdeleteLoadind(false)
    })

};