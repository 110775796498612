import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState } from 'react';
import { Galleria } from 'primereact/galleria';

const ModalProjectData = ({
    isLang,
    data,
    open,
    setOpen,
    dataImages,
    setOpenImages,
    openImages
}) => {
    const hideScopesProjectsDialog = () => setOpen(false);
    const hideImagesProjectsDialog = () => setOpenImages(false);
    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center align-items-center'>
            <img src={rowData.image} alt={rowData.image} className="shadow-2 border-round w-100" />;
        </div>
    };
    const [images, setImages] = useState(null);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    const itemTemplate = (item) => {
        return <img src={item.image} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.image} alt={item.alt} style={{ display: 'block', width: '80px', height: '80px' }} />;
    }
    return (

        <>
            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={open}
                style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
                header={isLang === "en" ? "Scope " : 'نِطَاق  '}
                modal
                onHide={hideScopesProjectsDialog}>
                {/* Add DataTable to display data */}
                <DataTable value={data} className="p-datatable-striped d-flex justify-content-center align-items-center" >
                    <Column field="en"   header={isLang === "en" ? "Scope (English)" : 'نِطَاق بالانجليزي'}/>
                    <Column field="ar"   header={isLang === "en" ? "Scope (Arabic)" : 'نِطَاق بالعربي'}/>
                </DataTable>
            </Dialog>   
            
            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'}
                visible={openImages}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header={isLang === "en" ? "Images" : 'الصور'}
                modal
                onHide={hideImagesProjectsDialog}>

                <Galleria
                    value={dataImages}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    circular
                    style={{ maxWidth: '640px' }}
                    showItemNavigators
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                />

            </Dialog>
            )

        </>
    );
};

export default ModalProjectData;
