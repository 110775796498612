
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetData, PostData, apiheader } from '../../utils/fetchData';
import { VendersContext } from './../../context/Store';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Editor } from 'primereact/editor';
const UpdateBlogs = () => {
  let { isLang } = useContext(VendersContext);
  let { id } = useParams()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/blogs/${id}/update`;
  const urlFetchData = `${process.env.REACT_APP_API_URL}/blogs/${id}`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const toast = useRef(null);
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const [editedImages, setEditedImages] = useState([]);

  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/blogs'}> {isLang==="en"?'Blogs':'مدونات'}</Link> },
    { label: <Link to={`/blogs/edit/${id}`} className='bg-orange-100 p-2 border-round'>{isLang==="en"?'Update':'تحديث'}</Link> }
  ];
  const fetchSliderData = async () => {
    let { data } = await GetData(urlFetchData, apiheader)
    setData(data);
    setEditedImages(data.images);

  }
  useEffect(() => {
    fetchSliderData()
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
  const home = { icon: 'pi pi-home', url: '/' };
  // TODO Handle 1 logo select 

  //!hide images modal 
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)

  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])

  const formik = useFormik({
    initialValues: {
      title_en: Data?.title_en || '',
      title_ar: Data?.title_ar || '',
      description_en: Data?.description_en || '',
      description_ar: Data?.description_ar || '',
      short_title_en: Data?.short_title_en || '',
      short_title_ar: Data?.short_title_ar || '',
      short_description_en: Data?.short_description_en || '',
      short_description_ar: Data?.short_description_ar || '',
      image: null,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await PostData(urlUpdateData, values, apiheader);
        console.log(data);
        if (data?.status === 200) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/blogs')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.message, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.message, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.errors?.description_ar);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        if (response.data.errors?.description_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_en, life: 3000 });
        if (response.data.errors?.description_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_ar, life: 3000 });
        if (response.data.errors?.title_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_en, life: 3000 });
        if (response.data.errors?.title_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_ar, life: 3000 });
        setLoading(false);
      }
    }
  });
  useEffect(() => {
    formik.setFieldValue('image', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    formik.setValues({
      title_en: Data?.title_en || '',
      title_ar: Data?.title_ar || '',
      description_en: Data?.description_en || '',
      description_ar: Data?.description_ar || '',
      short_title_en: Data?.short_title_en || '',
      short_title_ar: Data?.short_title_ar || '',
      short_description_en: Data?.short_description_en || '',
      short_description_ar: Data?.short_description_ar || '',
    });
  }, [Data, formik.setValues]);
  const handleDeleteImageFunc = async (id) => {

    await axios.delete(`${process.env.REACT_APP_API_URL}/blogs/${id}/delete-image`, apiheader).then(({ data }) => {
      console.log(data);
      if (data?.status) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.message, life: 3000 });

        setTimeout(() => {
          fetchSliderData()
        }, 1000);
      }
    }).catch(({ response }) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
      if (response.data.errors?.description_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_en, life: 3000 });
      if (response.data.errors?.description_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.description_ar, life: 3000 });
      if (response.data.errors?.title_en) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_en, life: 3000 });
      if (response.data.errors?.title_ar) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.title_ar, life: 3000 });
      if (response.data.errors?.link) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.link, life: 3000 });
      if (response.data.errors?.duration) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.duration, life: 3000 });
      if (response.data.errors?.image) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.image, life: 3000 });
      if (response.data.errors?.images) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.images, life: 3000 });
      if (response.data.errors?.scopes) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.scopes, life: 3000 });
      if (response.data.errors?.logo) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.logo, life: 3000 });
      if (response.data.errors?.section) toast.current.show({ severity: 'info', summary: 'Info', detail: response.data.errors?.section, life: 3000 });
    })

  };
  return (
    <div>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="mt-3  flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
          {formik.values.image ? (
            <img
              loading="lazy"
              src={URL.createObjectURL(formik.values.image)}
              alt={formik.values.image.name}
              className=' rounded-circle mx-auto '
              style={{ border: '6px solid #fff', width: '300px', height: '200' }}
            />
          ) :
            <>
              <img
                loading="lazy"
                src={Data.image}
                alt={'default-image'}
                className=' rounded-circle mx-auto '
                style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
              />
            </>
          }
          <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
            <input
              type="file"
              id="file-input"
              accept="image/*"
              onChange={handleImageSelect}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
              <i className='pi pi-image color-wight'></i>
            </label>
          </div>
        </div>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="title_en" className="font-bold block mb-2">   {isLang === "en" ? "title_en" : 'العنوان بالانجليزي'}      </label>
            <InputText
              name='title_en'
              id="title_en"
              keyfilter="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title_en}

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="title_ar" className="font-bold block mb-2">   {isLang === "en" ? "title_ar" : 'العنوان بالعربي '} </label>
            <InputText value={formik.values.title_ar} name='title_ar' id="title_ar" keyfilter="text" className="w-full" dir='rtl' onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="description_en" className="font-bold block mb-2">   {isLang === "en" ? "Description en" : '  وصف بالانجليزي'}     </label>
            <Editor
              id="description_en"
              style={{ height: '320px' }}
              value={formik.values.description_en}
              dir='ltr'
              onTextChange={(e) => formik.setFieldValue("description_en", e.htmlValue)}
            />

          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
            <label htmlFor="description_ar" className="font-bold block mb-2">  {isLang === "en" ? "Description ar" : 'وصف بالعربي'}  </label>
            <Editor
              id="description_ar"
              style={{ height: '320px' }}
              value={formik.values.description_ar}
              onTextChange={(e) => formik.setFieldValue("description_ar", e.htmlValue)}
              dir='ltr'
            />

          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="short_title_en" className="font-bold block mb-2">  {isLang === "en" ? "short_title_en" : '  عنوان قصير بالانجليزي'}    </label>
            <InputText value={formik.values.short_title_en} name='short_title_en' id="short_title_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
            <label htmlFor="short_title_ar" className="font-bold block mb-2">  {isLang === "en" ? "short_title_ar" : 'عنوان قصير بالعربي '} </label>
            <InputText value={formik.values.short_title_ar} name='short_title_ar' id="short_title_ar" keyfilter="text" className="w-full" dir='rtl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-2   ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="short_description_en" className="font-bold block mb-2">  {isLang === "en" ? "short_description_en" : '  وصف قصير بالانجليزي'}    </label>
            <InputText value={formik.values.short_description_en} name='short_description_en' id="short_description_en" keyfilter="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col">
            <label htmlFor="short_description_ar" className="font-bold block mb-2">   {isLang === "en" ? "short_description_ar" : '  وصف قصير بالعربي'} </label>
            <InputText value={formik.values.short_description_ar} name='short_description_ar' id="short_description_ar" keyfilter="text" className="w-full" dir='rtl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">  {isLang === "en" ? "Add Images " : 'إضافة الصور   '}   </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={isLang === "en" ? "Images " : ' الصور'} type='button' severity="" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
            </div>
          </div>
        </div>

        <div className="group btn_gapAr flex justify-content-center gap-4 ">
          <Button icon="pi pi-check" loading={loading} label={isLang == "en" ? 'Submit' : 'إرسال  '} type="submit" size='small'  className='mt-3' />
          <Link to={'/blogs'}>
            <Button label={isLang == "en" ? 'Cancel' : 'إلغاء  '} type="reset" outlined size='small'  className='mt-3' />
          </Link>
        </div>
      </form>


      <Dialog visible={openImanes}
        style={{ width: '50%' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={isLang === "en" ? "Multi Images" : "صور متعددة"}
        dir={isLang === "en" ? 'ltr' : 'rtl'}
        modal
        onHide={hideImagesProjectsDialog}
      >
        <div className="grid mt-2 flex flex-column">
          <div className="mt-3 mb-3 flex justify-content-center">
            <div className="mt-3 mb-3 flex justify-content-center">
              <label htmlFor='fileInput ' className='btn_gapAr' >
                <Button label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
              </label>
              <input
                type='file'
                id='fileInput'
                style={{ display: 'none' }}
                onChange={(event) => {
                  formik.setFieldValue('images', [...selectMultiImage, event.target.files[0]]);
                  handleMultiImageSelect(event);
                }}
              />
            </div>
          </div>


          {/*  Display selected images */}
          <div className="grid  mt-2   ">
            {selectMultiImage.map((image, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                <img src={URL.createObjectURL(image)} alt={`Image ${index}`} className='w-full h-8rem border-round-xl' />
              </div>
            ))}
          </div>
          <div className="grid  mt-2   ">
            {editedImages.map((item, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col">
                <div className="relative  ">
                  <img
                    src={item.image}
                    alt={`Image ${index}`}
                    style={{ objectFit: 'cover' }}
                    className="w-full h-8rem border-round-xl"
                  />
                  <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute right-0 " onClick={() => handleDeleteImageFunc(item.id)} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default UpdateBlogs
